.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  height: 100%;
  background-color: rgba(211, 198, 241) 
}

div {
  color: rgba(119, 120, 124, 5)
}
div.label {
  font-weight: bold;
}

#result {
  font-size: 3em;
  line-height: 100pt;
}

#calculate:hover {
  background-color: rgba(48,47,78,5);
}

strong.amount {
  float: right;
}

div.result_wrapper {
  width: 65%;
}

div.calc_result {
  margin-top: 20px;
}

.calc_label_tax::before {
  content: "\25A0";
  color: rgba(151,126,196, 1);
}

.calc_label_top::before {
  content: "\25A0";
  color:  rgba(223,136,133, 1);
}

.calc_label_total_tax::before {
  content: "\25A0";
  color: rgba(235,186,133, 1);
}

.calc_label_income::before {
  content: "\25A0";
  color: rgba(156, 211, 194, 1);
}

.logo {
  margin-bottom: 10px;
}